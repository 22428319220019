








































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import NavigationLink from '../components/Common/NavigationLink.vue';

@Component({
  components: {
    NavigationLink,
  },
})
export default class EmergencyContacts extends Mixins(View) {
  print(): void {
    window.print();
  }
}
